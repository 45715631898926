import config from "../../config";
import axios from "axios";

const backendURL = config.API.LHC_BACKEND_URL;
const core_backend_url = config.API.CORE_BACKEND;

const endPoints = {
  getLastDBUpdate: "/utils/lastUpdateLog",
  updateBookingDB: "/utils/getAllBooking",
};

const coreEndPoint = {
  getRangos: `${core_backend_url}/core/lhc/rangos`,
  getClient: `${core_backend_url}/core/cliente`,
  addPuntos: `${core_backend_url}/lhc/addPuntos`,
  getPaquetes: `${core_backend_url}/core/lhc/paquetes`,
  getTipos: `${core_backend_url}/core/lhc/tipos`,
  getUploadURL: `${core_backend_url}/upload`,
  getFileURL: `${core_backend_url}/getFile`,
  paises: `${core_backend_url}/lhc/pais`,
  marcas: `${core_backend_url}/lhc/marcas`,
  tickets: `${core_backend_url}/lhc/tickets`,
  categorias: `${core_backend_url}/lhc/categoria`,
  lhcUser: `${core_backend_url}/lhc/user`,
  firebase: `${core_backend_url}/firebase`,
};

interface cliente {
  type: string;
  value: string;
}
const getCliente = async (data: cliente) => {
  const payload =
    data.type === "id"
      ? {
          idCliente: data.value,
          emailCliente: "",
        }
      : {
          idCliente: "",
          emailCliente: data.value,
        };

  try {
    const { data } = await axios.get(coreEndPoint.getClient, {
      params: payload,
    });

    return data;
  } catch (error: any) {
    console.log(error.message);
    throw new Error("Error al obtener datos del cliente.");
  }
};

const getLastDBUpdate = async () => {
  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.getLastDBUpdate}`
    );
    return data;
  } catch (error) {
    throw new Error("Error al obtener la ultima actualizacion");
  }
};

const updateBookingDB = async () => {
  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.updateBookingDB}`,
      {
        params: {
          type: "Manual",
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error al actualizar la base de datos de reservas");
  }
};

const getRangos = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.getRangos);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los rangos");
  }
};

const getPaquetes = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.getPaquetes);

    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const getTipos = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.getTipos);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

interface IuploadBody {
  contentType: string;
  ext: string | undefined;
  isPublic: boolean;
}

const getUploadURL = async (body: IuploadBody) => {
  try {
    const { data } = await axios.post(coreEndPoint.getUploadURL, body);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const getFileURL = async (fileName: string) => {
  try {
    const { data } = await axios.get(coreEndPoint.getFileURL, {
      params: {
        fileName: fileName,
      },
    });

    return data;
  } catch (error) {
    throw new Error("Error no se encontro el archivo");
  }
};

interface IaddPuntos {
  idCliente: string;
  cantidad: number;
  concepto: string;
  tipo: number;
}

const addPuntos = async (payload: IaddPuntos) => {
  try {
    const { data } = await axios.post(coreEndPoint.addPuntos, payload);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

// paises

const getPaises = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.paises);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const getPais = async (idPais: number) => {
  try {
    const { data } = await axios.get(`${coreEndPoint.paises}/${idPais}}`);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

interface Ipais {
  nombrePais: string;
  codigoPais: string;
}

const createPais = async (body: Ipais) => {
  try {
    const { data } = await axios.post(coreEndPoint.paises, body);
    return data;
  } catch (error) {
    throw new Error("Error al crear datos");
  }
};

const updatePais = async (idPais: number, body: Ipais) => {
  try {
    const { data } = await axios.put(`${coreEndPoint.paises}/${idPais}`, body);
    return data;
  } catch (error) {
    throw new Error("Error al modificar datos");
  }
};

const deletePais = async (idPais: number) => {
  try {
    const { data } = await axios.delete(`${coreEndPoint.paises}/${idPais}`);
    return data;
  } catch (error) {
    throw new Error("Error al borrar datos");
  }
};

// marcas

const getMarcas = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.marcas);

    const result = data.map((el: any) => {
      return {
        ...el,
        paises: JSON.parse(el.paises),
      };
    });
    return result;
  } catch (error) {
    console.log(error);
    throw new Error("Error al obtener los datos");
  }
};

const getMarca = async (idMarca: number) => {
  try {
    const { data } = await axios.get(`${coreEndPoint.marcas}/${idMarca}`);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

interface Imarca {
  nombreMarca: string;
  descripcionMarca: string;
  paises: Array<any>;
  imagenMarca: string;
  codigoMarca: string;
  porcentajeRecompensa: number;
  estado: string;
  categoria: string;
}

const createMarca = async (body: Imarca) => {
  // transform array of string to string.
  const payload = {
    ...body,
    paises: JSON.stringify(body.paises),
  };
  try {
    const { data } = await axios.post(coreEndPoint.marcas, payload);
    return data;
  } catch (error) {
    throw new Error("Error al crear datos");
  }
};

const updateMarca = async (idMarca: number, body: Imarca) => {
  // transform array of string to string.
  const payload = {
    ...body,
    paises: JSON.stringify(body.paises),
  };
  try {
    const { data } = await axios.put(
      `${coreEndPoint.marcas}/${idMarca}`,
      payload
    );
    return data;
  } catch (error) {
    throw new Error("Error al modificar datos");
  }
};

const deleteMarca = async (idMarca: number) => {
  try {
    const { data } = await axios.delete(`${coreEndPoint.marcas}/${idMarca}`);
    return data;
  } catch (error) {
    throw new Error("Error al borrar datos");
  }
};

const getTickets = async (page: number = 1, pageSize: number = 10) => {
  try {
    const { data } = await axios.get(coreEndPoint.tickets, {
      params: {
        page: page,
        pageSize: pageSize,
      },
    });

    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const getTicketsByUser = async (idUser: string) => {
  try {
    const { data } = await axios.get(coreEndPoint.tickets, {
      params: {
        idUser: idUser,
      },
    });

    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

interface Iticket {
  idUsuario: string;
  codigoMarca: number;
  codigoPais: number;
  fechaCreacion: string;
  fecha: string;
  importeTicket: number;
  importeRecompensa: number;
  estado: string;
  referenciaTicket: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  comentarios?: string;
}

const updateTicket = async (idTicket: number, body: Iticket) => {
  try {
    const { data } = await axios.put(
      `${coreEndPoint.tickets}/${idTicket}`,
      body
    );
    return data;
  } catch (error) {
    throw new Error("Error al actualizar datos");
  }
};

const deleteTicket = async (idTicket: number) => {
  try {
    const { data } = await axios.delete(`${coreEndPoint.tickets}/${idTicket}`);
    return data;
  } catch (error) {
    throw new Error("Error al borrar datos");
  }
};

// categorias
const getCategorias = async () => {
  try {
    const { data } = await axios.get(coreEndPoint.categorias);

    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const getCagetoria = async (id: number) => {
  try {
    const { data } = await axios.get(`${coreEndPoint.categorias}/${id}`);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

interface ICategoria {
  nombre: string;
  slug: string;
}

const createCategoria = async (body: ICategoria) => {
  try {
    const payload: ICategoria = {
      nombre: body.nombre,
      slug: body.slug,
    };
    const { data } = await axios.post(coreEndPoint.categorias, payload);
    return data;
  } catch (error) {
    throw new Error("Error al crear los datos");
  }
};

const updateCategoria = async (id: number, body: ICategoria) => {
  try {
    const payload: ICategoria = {
      nombre: body.nombre,
      slug: body.slug,
    };

    const { data } = await axios.put(
      `${coreEndPoint.categorias}/${id}`,
      payload
    );
    console.log(data);
    return data;
  } catch (error) {
    throw new Error("Error al actualizar los datos");
  }
};

const deleteCategoria = async (id: number) => {
  try {
    const { data } = await axios.delete(`${coreEndPoint.categorias}/${id}`);
    return data;
  } catch (error) {
    throw new Error("Error al borrar la categoria");
  }
};

// lhc user
const lhcUserGetAll = async () => {
  try {
    const { data } = await axios.get(`${coreEndPoint.lhcUser}`);
    return data;
  } catch (error) {
    throw new Error("Error al Buscar usuarios registrados");
  }
};

// firebase
interface IFirebaseMessage {
  title: string;
  body: string;
}

interface IFirebasePayloadSingle {
  token: string;
  message: IFirebaseMessage;
}

interface IFirebasePayloadMulticast {
  tokens: Array<string>;
  message: IFirebaseMessage;
  users: Array<string>;
  fecha?: string;
  descripcion?: string;
  enlace?: string;
  lugar?: string;
  otros?: string;
}

const sendSingleNotification = async (
  token: string,
  message: IFirebaseMessage
) => {
  try {
    const payload: IFirebasePayloadSingle = {
      token: token,
      message: {
        title: message.title,
        body: message.body,
      },
    };
    const { data } = await axios.post(
      `${coreEndPoint.firebase}/send-single-notification`,
      payload
    );
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const sendMulticastNotification = async (
  tokens: Array<string>,
  message: IFirebaseMessage,
  users: Array<string>,
  extra: {
    fecha?: string;
    descripcion?: string;
    enlace?: string;
    lugar?: string;
    otro?: string;
  }
) => {
  try {
    console.log(tokens);
    const payload: IFirebasePayloadMulticast = {
      tokens: tokens,
      message: {
        title: message.title,
        body: message.body,
      },
      users: users,
      fecha: extra.fecha,
      descripcion: extra.descripcion,
      enlace: extra.enlace,
      lugar: extra.lugar,
      otros: extra.otro,
    };

    console.log(payload);

    const { data } = await axios.post(
      `${coreEndPoint.firebase}/send-multi-notification`,
      payload
    );
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const getNotificationLogs = async () => {
  try {
    const { data } = await axios.get(`${coreEndPoint.firebase}/getLogs`);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const core = {
  getLastDBUpdate,
  updateBookingDB,
  getCliente,
  getRangos,
  getPaquetes,
  getTipos,
  getUploadURL,
  getFileURL,
  addPuntos,
  paises: {
    getPaises,
    getPais,
    createPais,
    updatePais,
    deletePais,
  },
  marcas: {
    getMarcas,
    getMarca,
    createMarca,
    updateMarca,
    deleteMarca,
  },
  tickets: {
    getTickets,
    getTicketsByUser,
    updateTicket,
    deleteTicket,
  },
  categorias: {
    getCategorias,
    getCagetoria,
    createCategoria,
    updateCategoria,
    deleteCategoria,
  },
  lhcUser: {
    lhcUserGetAll,
  },
  firebase: {
    sendSingleNotification,
    sendMulticastNotification,
    getNotificationLogs,
  },
};

export default core;
