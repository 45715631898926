import axios from "axios";
import config from "../../config";

const backendURL = `${config.API.ACADEMY_BACKEND}/academy`;

const endPoints = {
  cursos: `${backendURL}/cursos`,
  modulos: `${backendURL}/modulos`,
  temas: `${backendURL}/temas`,
  curso: `${backendURL}/curso`,
  modulo: `${backendURL}/modulo`,
  tema: `${backendURL}/tema`,
};

const getCurso = async (idcurso: number) => {
  const params = {
    idCurso: idcurso,
  };
  try {
    const { data } = await axios.get(endPoints.curso, {
      params: params,
    });
    return data[0];
  } catch (error: any) {
    throw error;
  }
};

const getCursos = async () => {
  try {
    const { data } = await axios.get(endPoints.cursos);
    return data;
  } catch (error: any) {
    throw new Error("Error al obtener los datos");
  }
};
const getModulos = async () => {
  try {
    const { data } = await axios.get(endPoints.modulos);
    return data;
  } catch (error: any) {
    throw new Error("Error al obtener los datos");
  }
};
const getModulosByCursoID = async (cursoID: number) => {
  const params = {
    byIdCurso: true,
    idCurso: cursoID,
  };
  try {
    const { data } = await axios.get(endPoints.modulos, {
      params: params,
    });
    return data;
  } catch (error: any) {
    throw new Error("Error al obtener los datos");
  }
};
const getTemas = async () => {
  try {
    const { data } = await axios.get(endPoints.temas);
    return data;
  } catch (error: any) {
    throw new Error("Error al obtener los datos");
  }
};

const getTemasByModuloID = async (moduloID: number) => {
  const params = {
    byIdModulo: true,
    idModulo: moduloID,
  };
  try {
    const { data } = await axios.get(endPoints.temas, {
      params: params,
    });
    return data;
  } catch (error: any) {
    throw new Error("Error al obtener los datos");
  }
};

interface ICurso {
  titulo: string;
  url_imagen: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}
interface IModulo {
  idcurso: number;
  titulo: string;
  url_imagen: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface ITema {
  idmodulo: number;
  titulo: string;
  url_imagen: string;
  descripcion: string;
  contenido?: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const createCurso = async (body: ICurso) => {
  const payload = {
    titulo: body.titulo,
    descripcion: body.descripcion,
    url_imagen: body.url_imagen,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };
  try {
    await axios.post(endPoints.curso, payload);
  } catch (error) {
    throw new Error("Error al crear curso");
  }
};
const updateCurso = async (body: ICurso, idCurso: number) => {
  const payload = {
    titulo: body.titulo,
    descripcion: body.descripcion,
    url_imagen: body.url_imagen,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };
  try {
    await axios.put(endPoints.curso, payload, {
      params: {
        idCurso: idCurso,
      },
    });
  } catch (error) {
    console.log(error);
    throw new Error("Error al editar curso");
  }
};
const deleteCurso = async () => {};
const getModulo = async (idModulo: number) => {
  const params = {
    idModulo: idModulo,
  };

  try {
    const { data } = await axios.get(endPoints.modulo, {
      params: params,
    });
    return data[0];
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};
const createModulo = async (body: IModulo) => {
  const payload = {
    idcurso: body.idcurso,
    titulo: body.titulo,
    url_imagen: body.url_imagen,
    descripcion: body.descripcion,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };

  try {
    await axios.post(endPoints.modulo, payload);
  } catch (error) {
    throw new Error("Error al crear modulo");
  }
};
const updateModulo = async (body: IModulo, idModulo: number) => {
  const payload = {
    idcurso: body.idcurso,
    titulo: body.titulo,
    url_imagen: body.url_imagen,
    descripcion: body.descripcion,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };

  try {
    await axios.put(endPoints.modulo, payload, {
      params: {
        idModulo: idModulo,
      },
    });
  } catch (error) {
    throw new Error("Error al editar modulo");
  }
};
const deleteModulo = async () => {};

const getTema = async (idTema: number) => {
  const params = {
    idTema: idTema,
  };

  try {
    const { data } = await axios.get(endPoints.tema, {
      params: params,
    });
    return data[0];
  } catch (error) {
    throw new Error("Error al obtener los datos");
  }
};

const createTema = async (body: ITema) => {
  const payload = {
    idmodulo: body.idmodulo,
    titulo: body.titulo,
    url_imagen: body.url_imagen,
    descripcion: body.descripcion,
    contenido: body.contenido,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };

  try {
    await axios.post(endPoints.tema, payload);
  } catch (error) {
    throw new Error("Error al crear tema");
  }
};
const updateTema = async (body: ITema, idTema: number) => {
  const payload = {
    idmodulo: body.idmodulo,
    titulo: body.titulo,
    url_imagen: body.url_imagen,
    descripcion: body.descripcion,
    contenido: body.contenido,
    estado: body.estado,
    onlyForRango: body.onlyForRango,
    onlyForPaquete: body.onlyForPaquete,
    onlyForTipo: body.onlyForTipo,
  };

  try {
    await axios.put(endPoints.tema, payload, {
      params: {
        idTema: idTema,
      },
    });
  } catch (error) {
    throw new Error("Error al modificar tema");
  }
};
const deleteTema = async (temaID: number) => {
  try {
    await axios.delete(endPoints.tema, {
      params: {
        idTema: temaID,
      },
    });
  } catch (error) {
    throw new Error("Error al borrar tema");
  }
};

const academy = {
  curso: {
    getCursos,
    getCurso,
    createCurso,
    updateCurso,
  },
  modulo: {
    getModulos,
    getModulosByCursoID,
    getModulo,
    createModulo,
    updateModulo,
  },
  temas: {
    getTemas,
    getTemasByModuloID,
    getTema,
    createTema,
    updateTema,
    deleteTema,
  },
};

export default academy;
